<template>
  <section>
    <!-- Basic Inputs start -->
    <Search></Search>
    <!-- Basic Inputs end -->

    <!-- Hoverable rows start -->
    <StoreProducts></StoreProducts>
    <!-- Hoverable rows end -->

    <!--<EditStore></EditStore>-->
  </section>
</template>

<script>
import Search from "@/views/store-product/Search";
import StoreProducts from "@/views/store-product/Products"

export default {
  name: "StoreProductHome",
  components: {Search, StoreProducts}
}
</script>

<style scoped>

</style>
